<template>
  <el-dialog
    :title="modalType === 'edit' ? '修改充电规则' : '添加充电规则'"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="金额" prop="amount">
        <el-input
          v-model="modalData.amount"
          placeholder="请输入收费金额/30分钟"
          :maxLength="50"
        >
          <template #append> 元/30分钟 </template>
        </el-input>
      </el-form-item>

      <el-form-item label="占用费" prop="placeholderAmount">
        <el-input
          v-model="modalData.placeholderAmount"
          placeholder="请输入占用费/30分钟"
          :maxLength="50"
        >
          <template #append> 元/30分钟 </template>
        </el-input>
      </el-form-item>

      <el-form-item label="免费占用时间" prop="freePlaceHolder">
        <el-input
          v-model="modalData.freePlaceHolder"
          placeholder="电充满后，可以免费占用时间"
          :maxLength="50"
        >
        <template #append> 分钟</template>
    </el-input>
      </el-form-item>

      <el-form-item label="临时借电费用" prop="tempAmount">
        <el-input
          v-model="modalData.tempAmount"
          placeholder="临时借电费用/次"
          :maxLength="50"
        >
        <template #append>元/次</template>
      </el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>

      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>
  
  <script>
import modalMixin from "@/mixins/modalMixin";
import { mapState } from "vuex";

export default {
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList || { list: [], map: {} };
    },
  }),

  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData", "modalType"],
  data() {
    let operationId = this.$store.getters.userInfo.operationId;
    return {
      operationId: operationId,
      isShowNum: false,
      formName: "form",
      ruleValidate: {
        amount: [
          {
            required: true,
            message: "收费金额/30分钟不能为空",
            trigger: "change",
          },
        ],
        freePlaceHolder: [
          {
            required: true,
            message: "占用费/30分钟不能为空",
            trigger: "change",
          },
        ],
        placeholderAmount: [
          {
            required: true,
            message: "电充满后，可以免费占用时间不能为空",
            trigger: "change",
          },
        ],
        tempAmount: [
          {
            required: true,
            message: "临时借电费用不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    // 修改使用次数
    changeNum() {
      this.isShowNum = !!this.modalData;
    },

    submit() {
      this.validateForm().then((res) => {
        let obj = {
          amount: this.modalData.amount * 100,
          freePlaceHolder: this.modalData.freePlaceHolder,
          placeholderAmount: this.modalData.placeholderAmount * 100,
          tempAmount: this.modalData.tempAmount * 100,
          id: this.modalData.id,
        };
        this.$emit("submit", obj);
      });
    },
  },
};
</script>
  