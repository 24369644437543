import request from '@/common/axios';

// 查询充电配置列表
export function fetchRechargeSetting(data) {
  return request({
    url: '/web/admin/rechargeRule/page',
    method: 'POST',
    data: {
      ...data
    }
  });
}

// 修改配置
export function updateRechargeSetting(data) {
  return request({
    url: '/web/admin/rechargeRule/update',
    method: 'POST',
    data: {
      ...data
    }
  });
}

// 添加配置
export function addRechargeSetting(data) {
  return request({
    url: '/web/admin/rechargeRule/add',
    method: 'POST',
    data: {
      ...data
    }
  });
}


// 修改状态
export function updateStatusRechargeSetting(data) {
  return request({
    url: '/web/admin/rechargeRule/update/status',
    method: 'POST',
    data: {
      ...data
    }
  });
}

// 用户充电记录
export function fetchRechargePage(data) {
  return request({
    url: '/web/admin/rechargeRule/page',
    method: 'POST',
    data: {
      ...data
    }
  });
}

// 可销售的电池
export function saleBatteryPage(data) {
  return request({
    url: '/web/admin/sale/battery/page',
    method: 'POST',
    data: {
      ...data
    }
  });
}

// 销售的电池绑定用户
export function bindUser(data) {
  return request({
    url: '/web/admin/sale/battery/bind',
    method: 'POST',
    data: data

  });
}

// 导入可销售的电池
export function importSaleBattery(data) {
  return request({
    url: '/web/admin/sale/battery/excel/import',
    method: 'POST',
    method: 'POST',
    data: data
  });
}

/********** 用户充电记录 *********/

export function rechargeOrderPage(data) {
  return request({
    url: '/web/admin/recharge/order/page',
    method: 'POST',
    method: 'POST',
    data: data
  });
}

// 余额消费记录  
export function fetchMoneyDetail(data) {
  return request({
    url: '/web/admin/money/detail/page',
    method: 'POST',
    data: data
  });
}